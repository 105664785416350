/*
# GSAP from previous site
*/

(function ($) {
	/*--------------------------------------------------------------
		# Footer - 8bit Logo hover animation
	--------------------------------------------------------------*/
	gsap.registerPlugin(ScrollTrigger);

	var logoHover = false;

	const logoTL = gsap.timeline({
		defaults: { duration: 1, ease: "linear.easeInOut", repeatDelay: 3 },
		onComplete: function () {
			if (logoHover) {
				logoTL.restart(true, false);
			}
		},
	});
	logoTL.pause();

	gsap.utils.toArray(".creator-logo__row-one path").forEach((section) => {
		logoTL
			.fromTo(
				section,
				{ fill: "#ffffff" },
				{ fill: "#ff0048", duration: 0.05 },
				0
			)
			.to(section, { fill: "#ffffff", duration: 1 }, 0.1);
	});

	gsap.utils.toArray(".creator-logo__row-two path").forEach((section) => {
		logoTL
			.fromTo(
				section,
				{ fill: "#ffffff" },
				{ fill: "#ff0048", duration: 0.05 },
				0.05
			)
			.to(section, { fill: "#ffffff", duration: 1 }, 0.2);
	});

	gsap.utils.toArray(".creator-logo__row-three path").forEach((section) => {
		logoTL
			.fromTo(
				section,
				{ fill: "#ffffff" },
				{ fill: "#ff0048", duration: 0.05 },
				0.1
			)
			.to(section, { fill: "#ffffff", duration: 1 }, 0.3);
	});

	gsap.utils.toArray(".creator-logo__row-four path").forEach((section) => {
		logoTL
			.fromTo(
				section,
				{ fill: "#ffffff" },
				{ fill: "#ff0048", duration: 0.05 },
				0.15
			)
			.to(section, { fill: "#ffffff", duration: 1 }, 0.4);
	});

	document
		.querySelector(".site-footer__creator-logo-link")
		.addEventListener("mouseenter", () => {
			logoTL.restart(true, false);
			logoHover = true;
		});

	document
		.querySelector(".site-footer__creator-logo-link")
		.addEventListener("mouseleave", () => {
			logoHover = false;
		});

	// JG - Contact Update 07-11/24
	if ($("#contact-intro__typed-strings").length) {
		new Typed("#contact-intro__type", {
			stringsElement: "#contact-intro__typed-strings",
			typeSpeed: 100,
			backSpeed: 50,
			backDelay: 1000,
			startDelay: 500,
			loop: true,
		});
	}
})(jQuery);
